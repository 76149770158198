import jwtDecode from "jwt-decode";
import { IAccountClientRehash, IAccountProfile } from "@/models/Account";
import { AuthToken, ILoginForm } from "@/models/AuthToken";
import { ICreateUpdateCustomerForm } from "@/models/Customer";
import {
  IChangePasswordForm,
  ICreateUpdateUserForm,
  ICreateUserIdentityRequest,
  IRegisterDTO,
  IUpdatePassword,
  IUpdateUserProfileForm,
  IVerifyOtpDTO,
} from "@/models/User";
import { AccountAPI } from "@/services/account";
import { ErrorResponse } from "@/services/axios/error";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import SupplyChainManagementModule from "@/store/modules/MetaSupplyChainManament";
import { getAccessToken, getTenantId, logoutIdentity } from "@/utils/storage";
import { IdentityAPI } from "@/services/identity";
import { CLIENT_ADMIN, OPS_ADMIN } from "@ems/constants";
import { IActivityLogDTO, IActivityLogParameter } from "@/models/ActivityLog";
import { IPagination } from "@/models/Pagination";

const name = "AccountModule";
const LOCAL_STORAGE = "LocalStorage";

if ((store.state as any)[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class AccountModule extends VuexModule {
  private session: null | AuthToken = null;
  private error = {} as ErrorResponse;
  private loading = false;
  private dataAccountProfile = {
    TenantId: getTenantId() ?? "",
  } as IAccountProfile;
  private dataAccountClientRehash = {} as IAccountClientRehash;

  private dataActivityLog = [] as IActivityLogDTO[];
  private paginationActivityLog = {} as IPagination;
  private isLoadingActivityLogs = false;
  private errorActivityLogs = {} as ErrorResponse;

  private userRole: string = CLIENT_ADMIN;

  get getUserInfo() {
    return this.session;
  }

  get getRole() {
    return this.userRole;
  }

  get isError() {
    return !isEmpty(this.error);
  }

  get isLoadingLogin() {
    return this.loading;
  }
  get AccountClientRehash() {
    return this.dataAccountClientRehash;
  }
  get ProfileAccount() {
    return this.dataAccountProfile;
  }

  get dataActivityLogsGetter() {
    return this.dataActivityLog;
  }

  get isLoadingActivityLogsGetter() {
    return this.isLoadingActivityLogs;
  }

  get paginationActivityLosgGetter() {
    return this.paginationActivityLog;
  }

  get errorActivityLogsGetter() {
    return this.errorActivityLogs;
  }

  @Mutation
  loginProcess() {
    this.loading = true;
  }

  @Mutation
  loginSuccess(token = "") {
    const session = new AuthToken(token);

    store.commit(`${LOCAL_STORAGE}/saveToken`, token);
    this.session = session;
    this.loading = false;
  }

  @Mutation
  loginFailure(error: ErrorResponse) {
    this.session = null;
    this.error = error;
    this.loading = false;

    ElMessage({
      message: error.message,
      grouping: true,
      type: "error",
    });
  }

  @Mutation
  logoutSuccess() {
    this.session = null;
    this.dataAccountProfile = {} as IAccountProfile;
  }

  @Mutation
  loginIdentityFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  loginIdentitySuccess(token = "") {
    const session = new AuthToken(token);

    store.commit(`${LOCAL_STORAGE}/saveToken`, token);
    this.session = session;
    this.error = {} as ErrorResponse;
    this.loading = false;
  }

  @Mutation
  createAccountSuccess() {
    ElMessage.success("Create new account success.");
    this.error = {} as ErrorResponse;
  }

  @Mutation
  createAccountFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  updateUserProfileSuccess() {
    ElMessage.success("Update user profile success.");
    this.error = {} as ErrorResponse;
  }

  @Mutation
  updateUserProfileFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  changePasswordSuccess() {
    ElMessage.success("Change your password success.");
    this.error = {} as ErrorResponse;
  }

  @Mutation
  changePasswordFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  registrationSuccess() {
    ElMessage.success("Create your account success.");
    this.error = {} as ErrorResponse;
  }

  @Mutation
  registrationFailure(error: ErrorResponse) {
    this.error = error;
    ElMessage.error(error);
  }

  @Mutation
  setUserRole(role: string) {
    this.userRole = role;
  }

  @Action({ rawError: true })
  async login(form: ILoginForm) {
    this.loginProcess();
    const servicesAccountAPI = new AccountAPI();
    const { data, error } = await servicesAccountAPI.login(form);

    if (error) {
      this.loginFailure(error);
      return null;
    } else {
      this.loginSuccess(data.Token);
      await this.getAccountProfile();
      return this.dataAccountProfile &&
        this.dataAccountProfile?.Roles?.length > 0
        ? this.dataAccountProfile.Roles
        : [];
    }
  }

  @Action({ rawError: true })
  async loginIdentity(tenantId?: string) {
    this.loginProcess();
    const accessToken = getAccessToken();
    this.loginIdentitySuccess(accessToken ?? "");
    await this.getAccountIdentityProfile(tenantId);
    return this.dataAccountProfile && this.dataAccountProfile?.Roles?.length > 0
      ? this.dataAccountProfile.Roles
      : [];
  }

  get dataAccountProfileGetter() {
    return this.dataAccountProfile;
  }
  @Mutation
  async getAccountProfileSuccess(data: IAccountProfile) {
    this.dataAccountProfile = data;
  }
  @Mutation
  getAccountProfileError(error: ErrorResponse) {
    this.error = error;
  }

  @Action({ rawError: true })
  async getAccountProfile() {
    const servicesAccountAPI = new AccountAPI(this.session?.token);
    const { data, error } = await servicesAccountAPI.fetchAccountProfile();

    if (error) {
      this.getAccountProfileError(error);
    } else {
      await this.getAccountProfileSuccess(data);
      await this.getAccount();
    }
  }

  @Action({ rawError: true })
  async getAccountIdentityProfile(tenantId?: string) {
    const servicesAccountAPI = new AccountAPI(this.session?.token);
    const { data, error } =
      await servicesAccountAPI.fetchAccountIdentityProfile(tenantId);
    if (error) {
      this.getAccountProfileError(error);
    } else {
      await this.getAccountProfileSuccess(data);
    }
  }

  @Action({ rawError: true })
  logout() {
    this.logoutSuccess();
    store.commit(`${LOCAL_STORAGE}/removeToken`);
    logoutIdentity();
  }

  @Action({ rawError: true })
  async createAccount(user: ICreateUpdateCustomerForm | ICreateUpdateUserForm) {
    const servicesAccountAPI = new AccountAPI();
    const { error } = await servicesAccountAPI.createAccount(user);
    if (error) {
      this.createAccountFailure(error);
    } else {
      this.createAccountSuccess();
    }
  }

  @Action({ rawError: true })
  async createUserIdentity(user: ICreateUserIdentityRequest) {
    const servicesAccountAPI = new AccountAPI();
    const { data, error } = await servicesAccountAPI.createUserServer(user);
    if (error) {
      this.loginIdentityFailure(error);
      return null;
    } else {
      this.loginProcess();
      const accessToken = getAccessToken();
      this.loginIdentitySuccess(accessToken ?? "");
      if (data && data.User && data.User.Roles && data.User.Roles.length > 0) {
        this.setUserRole(
          data.User.Roles[0] === OPS_ADMIN ? OPS_ADMIN : CLIENT_ADMIN
        );
      }
      return data;
    }
  }

  @Action({ rawError: true })
  async updateUserProfile(user: IUpdateUserProfileForm) {
    const servicesAccountAPI = new AccountAPI();
    const { error } = await servicesAccountAPI.updateUserProfile(user);
    if (error) {
      this.updateUserProfileFailure(error);
    } else {
      this.updateUserProfileSuccess();
      this.getAccountProfile();
    }
  }

  @Action({ rawError: true })
  async changePassword(form: IChangePasswordForm) {
    const servicesAccountAPI = new AccountAPI();
    const { error } = await servicesAccountAPI.changePassword(form);

    if (error) {
      this.changePasswordFailure(error);
    } else {
      this.changePasswordSuccess();
    }
  }

  @Action({ rawError: true })
  async registration(form: IRegisterDTO) {
    const servicesAccountAPI = new AccountAPI();
    const { data, error } = await servicesAccountAPI.registerRedeem(form);

    if (error) {
      this.registrationFailure(error);
    } else {
      this.registrationSuccess();
      return data;
    }
  }

  @Mutation
  forgotPasswordFailure(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.error = error;
  }
  @Mutation
  forgotPasswordSuccess() {
    ElMessage.success("OTP has been sent! Please check your email.");
    this.error = {} as ErrorResponse;
  }
  @Action({ rawError: true })
  async forgotPassword(form: { Email: string }) {
    const servicesAccountAPI = new AccountAPI();
    const { data, error } = await servicesAccountAPI.forgotPassword(form);

    if (error) {
      this.forgotPasswordFailure(error);
    } else {
      this.forgotPasswordSuccess();
      return data;
    }
  }

  @Mutation
  verifyOtpFailure(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.error = error;
  }
  @Action({ rawError: true })
  async verifyOtp(form: IVerifyOtpDTO) {
    const servicesAccountAPI = new AccountAPI();
    const { data, error } = await servicesAccountAPI.verifyOtp(form);

    if (error) {
      this.registrationFailure(error);
    } else {
      return data;
    }
  }

  @Action({ rawError: true })
  async updatePassword(form: IUpdatePassword) {
    const servicesAccountAPI = new AccountAPI();
    const { data, error } = await servicesAccountAPI.updatePassword(form);

    if (error) {
      this.registrationFailure(error);
    } else {
      return true;
    }
  }

  @Mutation
  getAccountSuccess(data: IAccountClientRehash[]) {
    this.dataAccountClientRehash =
      data?.length > 0 ? data[0] : ({} as IAccountClientRehash);
  }
  @Mutation
  getAccountFailure(error: ErrorResponse) {
    this.error = error;
  }
  @Action({ rawError: true })
  async getAccount() {
    const servicesAccountAPI = new AccountAPI();
    const { data, error } = await servicesAccountAPI.getAccount();
    if (error) {
      this.registrationFailure(error);
    } else {
      this.getAccountSuccess(data);
      await SupplyChainManagementModule.checkRole();
      return true;
    }
  }

  @Mutation
  setLoadingActivityLogs(loading: boolean) {
    this.dataActivityLog = [] as IActivityLogDTO[];
    this.isLoadingActivityLogs = loading;
  }
  @Mutation
  getActivityLogsSuccess(data: any) {
    this.errorActivityLogs = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataActivityLog = Collection;
    this.paginationActivityLog = rest;
    this.isLoadingActivityLogs = false;
  }
  @Mutation
  getActivityLogsFailure(error: ErrorResponse) {
    this.errorActivityLogs = error;
    this.isLoadingActivityLogs = false;
  }
  @Action({ rawError: true })
  async getActivityLogsAction(params?: IActivityLogParameter) {
    this.setLoadingActivityLogs(true);
    const servicesAccountAPI = new AccountAPI();
    const { data, error } = await servicesAccountAPI.getActivityLogs(params);
    if (error) {
      this.getActivityLogsFailure(error);
    } else {
      this.getActivityLogsSuccess(data);
    }
  }
}

export default getModule(AccountModule);
