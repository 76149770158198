import { IdentityAPI } from "@/services/identity";
import AccountModule from "@/store/modules/Account";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import queryString from "query-string";
import { IDataResponse } from "./entities";
import { IServiceError } from "./error";
import {
  getAccessToken,
  getEnv,
  getRefreshToken,
  logoutIdentity,
  setAccessToken,
  setIdToken,
  setRefreshToken,
} from "@/utils/storage";
import { HTTP_STATUS_CODE } from "@ems/constants/http_status_code";
import { RefreshTokenRequest } from "@/models/IdentityServer";
import { IDENTITY_GRANT_TYPE } from "@ems/constants/identity_grant_type";

let refreshTokenPromise: Promise<string> | null = null;

export default abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  protected readonly token?: string;
  protected readonly tenantId?: string;

  constructor(
    baseURL?: string,
    accountId?: string,
    token?: string,
    tenantId?: string
  ) {
    this.instance = axios.create({
      baseURL,
      headers: {
        "content-type": "application/json",
        "Accept-Account": accountId || "",
      },
      paramsSerializer: (params) =>
        queryString.stringify(params, { skipNull: true }),
    });
    this.token = token || "";
    this.tenantId = tenantId || "";
    this.requestInterceptor();
    this.responseInterceptor();
  }

  private requestInterceptor = () => {
    this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
      const oldToken = window.name;
      const currToken = getAccessToken();
      const env = getEnv();

      if (oldToken && currToken && currToken !== oldToken) {
        window.name = currToken;
        window.location.reload();
        return config;
      }

      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const session =
          AccountModule.getUserInfo?.authorizationString ??
          `Bearer ${currToken}` ??
          `Bearer ${this.token}`;

      (config.headers as any).Authorization = `${session}`;
      (config.headers as any)["Accept-Tenant"] =
        AccountModule.ProfileAccount?.TenantId || this.tenantId;
      (config.headers as any)["Accept-Timezone"] = tz;
      (config.headers as any)["X-Client-Id"] = env?.VUE_APP_IDENTITY_CLIENT_ID;
      return config;
    });
  };

  private responseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this.handleError
    );
  };

  private _handleResponse = ({ data, config }: AxiosResponse): any => {
    const res = data as IDataResponse;
    if (res.Data === undefined) {
      return { data: res };
    }
    const resHeaders = config.url;
    if (res.Code !== "Success") {
      const error = {
        headers: resHeaders,
        message: res.Message,
        isError: true,
        data: res.Data,
        code: res.Code,
      } as IServiceError;
      return Promise.reject(error);
    }
    return { data: res.Data };
  };

  protected handleError = async (error: any) => {
    const originalConfig = error.config;

    if (originalConfig._retry) {
      logoutIdentity();
      throw error;
    }

    if (
      error &&
      error.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;

      if (!refreshTokenPromise) {
        refreshTokenPromise = this.refreshToken();
      }

      try {
        const newAccessToken = await refreshTokenPromise;
        originalConfig.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return this.instance.request(originalConfig);
      } catch (refreshError) {
        logoutIdentity();
        throw refreshError;
      } finally {
        refreshTokenPromise = null;
      }
    }

    throw error;
  };

  private refreshToken(): Promise<string> {
    const env = getEnv();
    const refreshTokenReq: RefreshTokenRequest = {
      grantType: IDENTITY_GRANT_TYPE.REFRESH_TOKEN,
      clientId: env.VUE_APP_IDENTITY_CLIENT_ID,
      refreshToken: getRefreshToken() || "",
    };

    const serviceIdentityAPI = new IdentityAPI();

    return new Promise((resolve, reject) => {
      serviceIdentityAPI
        .refreshToken(refreshTokenReq)
        .then(({ data, error }) => {
          if (error || !data) {
            logoutIdentity();
            reject("Refresh token is invalid");
          } else {
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            setIdToken(data.id_token);
            resolve(data.access_token);
          }
        })
        .catch((err) => {
          logoutIdentity();
          reject(err);
        });
    });
  }
}
